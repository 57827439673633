import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

// vuex
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css';
import 'bootstrap/dist/css/bootstrap.min.css';

Vue.use(Vuesax,{
  // 配置
})
import 'material-icons/iconfont/material-icons.css'

// router路由
import router from './routers/index'

// 代理请求
import proxy from './proxy/api';
Vue.use(proxy)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
